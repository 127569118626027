<template>
  <div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-if="!loading" class="px-3 py-1">
      <b-card v-for="cred in creditos" class="mb-3" :key="`credit$_${cred.id}`">
        <div class="d-flex flex-column justify-content-between">
          <div class="mb-4">
            <h4><b>Código: </b> {{ cred.id }}</h4>
            <h4><b>Data: </b> {{ cred.created_at | moment("DD/MM/YYYY") }}</h4>
            <h5 class="d-flex justify-content-between">
              <div><b>Usuário: </b> {{ cred.usuario }}</div>
              <div>
                <b-btn variant="warning" @click="openHistorico(cred)"
                class="mr-2"
                  ><b-icon-clock-history /> Histórico de Uso</b-btn
                >
                <b-btn
                  variant="info"
                  @click="openVenda(cred.venda_id, cred.id)"
                >
                  <b>Venda nº:</b>
                  {{ cred.venda_id }}
                </b-btn>
              </div>
            </h5>
          </div>
          <div class="text-center d-flex justify-content-between">
            <b-card class="w-100 mx-2">
              <h2>
                Valor
                <div>
                  <small>
                    {{ cred.valor | currencyWithMask }}
                  </small>
                </div>
              </h2>
            </b-card>
            <b-card class="w-100 mx-2">
              <h2>
                Usado
                <div>
                  <small>
                    {{ cred.valor_usado | currencyWithMask }}
                  </small>
                </div>
              </h2>
            </b-card>
            <b-card class="w-100 mx-2">
              <h2>
                Restante
                <div>
                  <small>
                    {{ (cred.valor - cred.valor_usado) | currencyWithMask }}
                  </small>
                </div>
              </h2>
            </b-card>
          </div>
        </div>
        <b-modal
          :id="`modal-open-venda${cred.venda_id}${cred.id}`"
          hide-footer
          hide-header
          size="lg"
        >
          <venda-detail v-if="cred.venda_id" :vendaId="cred.venda_id" />
        </b-modal>
        <b-modal :id="'modal-show-hist' + cred.id" hide-footer hide-header>
          <b-table
            :items="cred.historico"
            :fields="[
              { key: 'venda_id', label: 'Código' },
              { key: 'data', label: 'Data' },
              { key: 'clinica', label: 'Clínica' },
              { key: 'vl_total', label: 'Valor Venda', class: 'text-center' },
              {
                key: 'valor_usado',
                label: 'Crédito Usado',
                class: 'text-center',
              },
            ]"
          >
            <tamplte #cell(venda_id)="row">
              {{ row.item.venda_id }}
              <div v-if="row.item.cancelado && row.item.cancelado == 1">
                <b-icon-x></b-icon-x> Cancelado
              </div>
            </tamplte>
            <template #cell(data)="row">
              {{ row.item.data | moment("DD/MM/YYYY") }}
            </template>

            <template #cell(vl_total)="row">
              {{ row.item.vl_total | currency }}
            </template>

            <template #cell(valor_usado)="row">
              {{ row.item.valor_usado | currency }}
            </template>
          </b-table>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import EntidadesLib from "../../../libs/EntidadesLib";
import VendaDetail from "../../Caixas/Vendas/VendaDetail.vue";

export default {
  components: { VendaDetail },
  props: {
    entidadeId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      creditos: [],
      loading: false,
      vendaId: 0,
      credSel: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    openHistorico(cred) {
      if (cred) {
        this.credSel = cred;
        this.$bvModal.show("modal-show-hist" + cred.id);
      }
    },
    openVenda(id, id_cred) {
      this.vendaId = id;
      this.$bvModal.show("modal-open-venda" + id + "" + id_cred);
    },
    async load() {
      try {
        this.loading = true;
        let res = await EntidadesLib.getCreditos(this.entidadeId);
        if (res && res.success) {
          this.creditos = res.data;
        }
        console.log("creditos", this.creditos);
        this.loading = false;
      } catch (error) {
        this.showToast("error", error.message, 3500);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
